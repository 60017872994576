import {
  Input as NextUIInput,
  type InputProps as NextUIInputProps,
} from '@nextui-org/input';
import { forwardRef } from '@nextui-org/react';

export interface InputProps extends NextUIInputProps {
  state?: 'valid' | 'invalid' | 'default';
  customSize?: string;
}

const Input = forwardRef(
  ({ state = 'default', size = 'lg', classNames = {}, customSize, ...rest }: InputProps, ref) => {
    const states = {
      valid:
        'border-green-300 hover:border-green-300 group-data-[focus=true]:border-green-300',
      invalid:
        'border-pure-red-500 hover:border-pure-red-500 group-data-[focus=true]:border-pure-red-500',
      default:
        'border-grey-400 hover:border-blue-500 group-data-[focus=true]:border-blue-500 group-data-[focus=true]:shadow-[0_0_0_1px_inset_rgba(67,139,247)]',
    }[state];

    const sizes = {
      sm: 'h-8 min-h-8 max-h-8',
      md: 'h-8.75 min-h-8.75 max-h-8.75',
      lg: 'h-9.5 min-h-9.5 max-h-9.5',
    }[size];

    return (
      <NextUIInput
        ref={ref}
        {...rest}
        variant={rest.readOnly ? 'faded' : 'bordered'}
        classNames={{
          label: 'text-sm font-semibold text-grey-900 select-none',
          inputWrapper: `${customSize || sizes} border rounded-md shadow-none p-0  ${
            rest.readOnly ? 'border-grey-400 bg-grey-50' : states
          }`,
          input: 'text-sm placeholder:text-grey-400 px-2',
          ...classNames
        }}
      />
    );
  }
);

export default Input;
