import React from 'react';
import { type InteractionSettings, type InteractionWithoutPDF } from '../types';

export default function useGetImageProperties() {
  const getImageProperties = (interaction: InteractionWithoutPDF, setting: Partial<InteractionSettings> = {}) => {
    let src = null;
    let opacity = 100;
    if (interaction === 'pollWithOptions') {
      src = setting.pollWithOptionsImageSrc;
      opacity = setting.pollWithOptionsImageOpacity ?? 100;
    } else if (interaction === 'freeForm') {
      src = setting.pollImageSrc;
      opacity = setting.pollImageOpacity ?? 100;
    } else if (interaction === 'map') {
      src = setting.mapImageSrc;
      opacity = setting.mapImageOpacity ?? 100;
    } else if (interaction === 'wheel') {
      src = setting.choiceCircleImageSrc;
      opacity = setting.choiceCircleImageOpacity ?? 100;
    } else if (interaction === 'wordCloud') {
      src = setting.wcImageSrc;
      opacity = setting.wcImageOpacity ?? 100;
    } else if (interaction === 'emojisEverywhere') {
      src = setting.emojisEverywhereImageSrc;
      opacity = setting.emojisEverywhereImageOpacity ?? 100;
    } else if (interaction === 'talkingTiles') {
      src = setting.talkingTilesImageSrc;
      opacity = setting.talkingTilesImageOpacity ?? 100;
    } else if (interaction === 'transientThought') {
      src = setting.ttImageSrc;
      opacity = setting.ttImageOpacity ?? 100;
    } else if (interaction === 'newWheel') {
      src = setting.winningWheelImageSrc;
      opacity = setting.winningWheelImageOpacity ?? 100;
    } else if (interaction === 'linkLibrary') {
      src = setting.linkLibraryImageSrc;
      opacity = setting.linkLibraryImageOpacity ?? 100;
    } else if (interaction === 'Quick Questions') {
      src = setting.quickQuestionImageSrc;
      opacity = setting.quickQuestionImageOpacity ?? 100;
    } else if (interaction === 'textTrack') {
      src = setting.textTrackImageSrc;
      opacity = setting.textTrackImageOpacity ?? 100;
    } else if (interaction === 'ratingPolls') {
      src = setting.ratingPollsImageSrc;
      opacity = setting.ratingPollsImageOpacity ?? 100;
    } else if (interaction === 'fantasticFans') {
      src = setting.ffImageSrc;
      opacity = setting.ffImageOpacity ?? 100;
    }
    return {
      src,
      opacity
    };
  };

  return {
    getImageProperties
  };
}
